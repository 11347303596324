import moment from 'moment'
import leafletImage from 'leaflet-image'

export const MONITORING = 'monitoring'
export const BASE = 'base'
export const GROUP = 'group'
export const DEMO_UNIT_ID = '6d3892de-421a-4f0e-bc13-330534fc3381'
export const DEMO_DEFAULT_DATE = ['2024-04-01 00:00:00', '2024-04-14 00:00:00']
export const DEMO_REPORT_NAME = 'поездки'

const FORMAT_TIME_23 = '23:59:59'
const FORMAT_TIME_00 = '00:00:00'

/**
 * Устанавливает диапазон дат для формы отчета на основе указанного периода.
 * @param {string} period - Период, для которого нужно установить диапазон дат.
 * Допустимые значения: 'today', 'yesterday', 'this_week', 'this_month'.
 * @param {string} formatTime - формат времени 00:00:00 / 23:59:59
 * @throws {Error} Выбрасывает ошибку, если период недействителен.
 */
export const selectPeriodReport = (period, timezone, activeCompanyFormatTime) => {
  // Определение текущей даты на основе this.formatTime
  let now = moment().tz(timezone).endOf('day')

  if (activeCompanyFormatTime === '00:00:00') {
    now = now.startOf('day').add(1, 'day')
  }

  let startDate
  let endDate

  switch (period) {
    case 'today':
      startDate = moment().tz(timezone).startOf('day')
      endDate = now.format('YYYY-MM-DD HH:mm:ss')
      break

    case 'yesterday':
      startDate = moment().tz(timezone).subtract(1, 'days').startOf('day')

      if (activeCompanyFormatTime === '00:00:00') {
        endDate = moment().tz(timezone).startOf('day').format('YYYY-MM-DD HH:mm:ss')
      } else {
        endDate = moment().tz(timezone).subtract(1, 'days').endOf('day').format('YYYY-MM-DD HH:mm:ss')
      }
      break

    case 'this_week':
      startDate = moment().tz(timezone).startOf('isoWeek')
      endDate = now.format('YYYY-MM-DD HH:mm:ss')
      break

    case 'this_month':
      startDate = moment().tz(timezone).startOf('month')
      endDate = now.format('YYYY-MM-DD HH:mm:ss')
      break

    default:
      throw new Error(`Неправильный период: ${period}`)
  }

  let formattedStartDate = startDate.format('YYYY-MM-DD HH:mm:ss')
  let formattedEndDate = endDate

  return [moment.tz(formattedStartDate, timezone).format('YYYY-MM-DD HH:mm:ss'), moment.tz(formattedEndDate, timezone).format('YYYY-MM-DD HH:mm:ss')]
}

/**
 * Возвращает диапазон дат в зависимости от значения formattedTime в localStorage.
 * @returns {Array} Массив из двух строковых значений дат: начало и конец дня.
 */
export const defaultValueDate = () => {
  const formattedTime = JSON.parse(localStorage.getItem('formattedTime'))

  const startOfDay = moment().set({ hour: 0, minute: 0, second: 0 })
  let startDate = startOfDay.format('YYYY-MM-DD HH:mm:ss')
  let endDate

  if (formattedTime === FORMAT_TIME_23) {
    endDate = startOfDay.set({ hour: 23, minute: 59, second: 59 }).format('YYYY-MM-DD HH:mm:ss')
  } else {
    endDate = startOfDay.add(1, 'days').format('YYYY-MM-DD HH:mm:ss')
  }

  return [startDate, endDate]
}

/**
 * Устанавливает форму отчета в локальное хранилище.
 * @param {Array} result - Результат, содержащий данные для проверки и установки формы.
 * @param {string} userId - Идентификатор пользователя.
 * @param {string} companyId - Идентификатор компании.
 * @param {string} activeCompanyId - Идентификатор активной компании.
 */
export const setReportFormLocalStorage = (result, userId, companyId, activeCompanyId) => {
  const storageKey = `reportsForm${userId}${companyId}`
  let storageReportForm = localStorage.getItem(storageKey)

  storageReportForm = storageReportForm ? JSON.parse(storageReportForm) : {}

  const currentCompanyForm = storageReportForm[activeCompanyId]
  let reportForm

  if (currentCompanyForm) {
    const form = currentCompanyForm
    const isUnitIdValid = (form.object === BASE && result[1].some(item => item.id === form.unitsId)) || (form.object === GROUP && result[2].some(item => item.id === form.unitsId))
    const isTableTypeValid = result[0].some(rt => rt.id === form.tableType)

    reportForm = {
      tableType: isTableTypeValid ? form.tableType : '',
      object: form.object,
      unitsId: isUnitIdValid ? form.unitsId : '',
      datePicker: form.datePicker
    }
  } else {
    reportForm = {
      tableType: result[0][0].id,
      object: currentCompanyForm?.object,
      unitsId: result[1].length ? result[1][0].id : '',
      datePicker: defaultValueDate()
    }

    storageReportForm[activeCompanyId] = reportForm
    localStorage.setItem(storageKey, JSON.stringify(storageReportForm))
  }
}

/**
 * Возвращается дата за последние сутки
 * @return {Object}
 */
export const getLast24Hour = () => {
  return {
    from: moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
    to: moment().add(1, 'days').startOf('day').format('YYYY-MM-DD HH:mm:ss')
  }
}

/**
 * Удаляет и добавляет слой карты с задержкой.
 * @param {Object} map - Объект карты.
 * @param {Object} layer - Слой карты.
 * @param {number} delay - Задержка в миллисекундах.
 */
const toggleLayerWithDelay = (map, layer, delay = 1000) => {
  if (map.hasLayer(layer)) {
    map.removeLayer(layer)
    setTimeout(() => {
      map.addLayer(layer)
    }, delay)
  }
}

/**
 * Генерирует изображение карты.
 * @param {Object} map - Объект карты.
 * @returns {Promise} Промис, который разрешается в строку base64 изображения.
 */
const generateMapImage = async map => {
  return new Promise((resolve, reject) => {
    leafletImage(map, (err, canvas) => {
      if (err) {
        return reject(err)
      }
      canvas.toBlob(blob => {
        const reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onloadend = () => resolve(reader.result.split(',')[1])
      })
    })
  })
}

/**
 * Создает изображение карты
 * @param {Object} map - Объект карты
 * @param {Array} markers - Массив слоев маркеров
 * @param {Array} arrows - Массив слоев стрелок
 * @param {number} delay - Задержка в миллисекундах для обработки слоев
 * @returns {Promise} Промис, который разрешается в строку base64 изображения карты
 */
export const createMapImage = async (map, markers = [], arrows = [], delay = 1000) => {
  // Обработка маркеров
  markers.forEach(marker => {
    toggleLayerWithDelay(map, marker, delay)
  })

  // Обработка стрелок
  arrows.forEach(arrow => {
    arrow.removeFrom(map)
    setTimeout(() => {
      arrow.removeFrom(map)
    }, delay)
  })

  // Генерация изображения карты
  try {
    const image = await generateMapImage(map)
    return image
  } catch (err) {
    throw new Error('Error generating map image: ' + err.message)
  }
}

/**
 * Добавление новый свойств в массив с отчетом
 * @param {Array} - массив строк отчета
 * @param {Object} - обьект отчета
 * @return {Array} - новый массив с новыми свойствами
 */
export const rowsWithNewProps = (reportsRows, data) => {
  return reportsRows.map((row, index) => ({
    ...row,
    isCollapsed: false,
    parentId: `${index}`,
    uniqId: `[first_datepoint-${row.first_datepoint}]-[last_datepoint-${row.last_datepoint}]`,
    reportTimeGroup: data.report.time_group || 'no_grouping',
    reportNextTimeGroup: data.report.next_time_group || 'no_grouping'
  }))
}

/**
 * Функция по удалению вложенных строк
 * @param {Array} - массив строк rows отчета
 * @param {String} - ParentId строки '0.0.0' как пример
 * @return {Array} - массив
 */
export const removeSubItems = (items, targetId) => {
  function startsWithPrefix(id, prefix) {
    return id.startsWith(prefix) && id !== prefix && id[prefix.length] === '.'
  }

  const targetLength = targetId.length

  return items.filter(item => {
    const id = item.parentId
    if (startsWithPrefix(id, targetId)) {
      return id.length === targetLength || id[targetLength] !== '.'
    }
    return true
  })
}
