<template>
  <div id="monitoring" class="monitoring">
    <div class="container">
      <div class="monitoring__header w-full">
        <span class="monitoring__title">{{ $t('monitoring') }}</span>
        <div @click="swapToReports" class="monitoring__toReport pointer">
          <ReportIcon />
          <span class="monitoring__toReportTitle"> {{ $t('reports') }} </span>
        </div>
        <div class="pointer monitoring__close">
          <el-tooltip :open-delay="700" effect="dark" :content="$t('monitoring.grouping_on_map')" placement="bottom" :offset="2" :visible-arrow="false">
            <el-popover width="465" v-model="isPopoveerSettingsOpen" @click="isPopoveerSettingsOpen = !isPopoveerSettingsOpen" popper-class="settings-popover" class="ml-4 z-30 pointer" placement="bottom-end" trigger="click">
              <SettingsMonitoring :objectAnnotationSwitch="objectAnnotationSwitch" @handlCloseSettingsPopup="isPopoveerSettingsOpen = !isPopoveerSettingsOpen" @changeObjectAnnotationSwitch="changeObjectAnnotationSwitch" />
              <span slot="reference">
                <SettingIcon class="radiusSettings" @click="showSettingsMonitoring" />
              </span>
            </el-popover>
          </el-tooltip>
          <i @click="handlClose" class="el-icon-close monitoring__closeIcon"></i>
        </div>
      </div>
      <div class="monitoring__search w-full">
        <input class="monitoring__input" :placeholder="$t('monitoring.object_search')" v-model="filterString" />
        <SearchIcon class="monitoring__search__icon pointer" />
      </div>
      <div class="monitoring__options w-full">
        <div @click="activateFilter" class="pointer monitoring__filter">
          <FilterIcon class="monitoring__filter-icon" />
          <span class="monitoring__filter-title">
            {{ $t('monitoring.apply_filter') }}
            <span
              class="monitoring__filter-number"
              :class="{
                empty: !hasFilters
              }"
            >
              {{ hasFilters ? filterLength : 0 }}
            </span>
          </span>
        </div>
        <div class="monitoring__btns">
          <el-tooltip :open-delay="700" effect="dark" :content="$t('monitoring.display_all')" placement="bottom" :offset="2" :visible-arrow="false">
            <div class="monitoring__btns-btn btn-eye pointer">
              <ActiveAllEyeIcon v-if="vuexIsActiveAllEye || vuexActiveEye.length === vuexActiveEyeLength" @click.native="showAllEye" />
              <AllEyeIcon v-else @click.native="showAllEye" />
            </div>
          </el-tooltip>

          <el-tooltip :open-delay="700" effect="dark" :content="$t('monitoring.track_all')" :offset="2" placement="bottom" :visible-arrow="false">
            <div class="monitoring__btns-btn btn-arrow pointer">
              <AllArrowIcon v-if="vuexIsActiveAllArrow" @click.native="showAllArrow" />
              <ActiveAllArrowIcon v-else @click.native="showAllArrow" />
            </div>
          </el-tooltip>

          <el-tooltip :open-delay="700" effect="dark" :content="$t('monitoring.objects_grouping')" placement="bottom" :offset="2" :visible-arrow="false">
            <div :class="['monitoring__btns-btn', 'btn-groups', 'pointer', { activeGroup: vuexIsActiveGroup }]">
              <!-- <CircleRepairIcon class="repair-icon" /> -->
              <!-- <GroupIcon /> -->
              <ActiveGroupIcon v-if="vuexIsActiveGroup" @click.native="showGroup" />
              <GroupIcon v-else @click.native="showGroup" />
            </div>
          </el-tooltip>
        </div>
      </div>
    </div>

    <div :class="['monitoring__table', { 'monitoring__table--isActiveGroup': vuexIsActiveGroup }]">
      <div class="monitoring__table-header">
        <div class="name-container">
          <span class="name">{{ $t('monitoring.columns.name') }}</span>
          <SortIconName class="sorting-icon pointer" :name-sort="sortName" :order-sort="sortOrder" @click.native="sortChange('name')" />
        </div>
        <div class="sorting-container">
          <SortIconEye class="sorting-icon pointer" :name-sort="sortName" :order-sort="sortOrder" @click.native="sortChange('eye')" />
          <SortIconArrow class="sorting-icon pointer" :name-sort="sortName" :order-sort="sortOrder" @click.native="sortChange('arrow')" />
          <SortIconDatepoint class="sorting-icon pointer" :name-sort="sortName" :order-sort="sortOrder" @click.native="sortChange('datepoint')" />
        </div>
      </div>

      <!-- Объекты -->
      <el-skeleton v-if="!vuexIsActiveGroup" :class="loading ? 'm-8' : ''" :rows="22" :loading="loading" animated>
        <RecycleScroller class="table w-full table-fixed scroller overflow-y-auto truncate" ref="scroller" id="scroller" :items="searchMonitoringList" :item-size="48" key-field="id" v-slot="{ item, index }">
          <div id="roow" class="truncate row table-row cursor-pointer hover_bg-reallylightblue" @click="showAnnotation(item, index)">
            <div class="row-name truncate">
              <el-popover popper-class="monitoring-popover" class="z-30 pointer" placement="bottom-start" trigger="hover">
                <div class="poppover-container">
                  <span @click="emitEditForm(item, 'update-unit')" class="poppover-item">
                    {{ $t('monitoring.change-object') }}
                  </span>
                  <div class="poppover-item">
                    <el-popover :index="mainPopoverKey" popper-class="monitoring-popover-reports" class="fasttrack" placement="right" trigger="hover">
                      <div class="items-container">
                        <span @click="showFastTrack(item, 'today')" class="poppover-item">
                          {{ $t('monitoring.fast_tracks.today') }}
                        </span>
                        <span @click="showFastTrack(item, 'yesterday')" class="poppover-item">
                          {{ $t('monitoring.fast_tracks.yesterday') }}
                        </span>
                        <span @click="showFastTrack(item, 'week')" class="poppover-item">
                          {{ $t('monitoring.fast_tracks.week') }}
                        </span>
                        <span @click="showFastTrack(item, 'month')" class="poppover-item">
                          {{ $t('monitoring.fast_tracks.month') }}
                        </span>
                        <!-- issues-3602 Вернуть в Next Release -->
                        <!--                        <span @click="togglePicker(item)" class="poppover-item"> {{ $t('monitoring.fast_tracks.custom') }}</span>-->
                        <!--                        <div v-if="isPickerOpen" class="custom-picker">-->
                        <!--                          {{ $t('monitoring.fast_tracks.custom') }}-->
                        <!--                          <el-date-picker @change="showCustomFastTrack" popper-class="fasttrack-datepicker" ref="picker" v-model="fasttrackDates" type="datetimerange" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" :clearable="false" :placeholder="$t('reports.not-select')" />-->
                        <!--                        </div>-->
                      </div>
                      <div class="title" slot="reference">
                        <span> {{ $t('monitoring.fast_tracks') }} </span>
                        <span class="fasttrack-icon">
                          <FastTrackIcon />
                        </span>
                      </div>
                    </el-popover>
                  </div>
                  <span @click="emitEditForm(item, 'update-unit-events')" class="poppover-item">
                    {{ $t('monitoring.create-event') }}
                  </span>
                  <span @click="openReport(item, 'update-unit-events')" class="poppover-item">
                    {{ $t('monitoring.to_reports') }}
                  </span>
                  <span @click="openCommands(item)" class="poppover-item">
                    {{ $t('monitoring.commands') }}
                  </span>
                </div>
                <div class="extra-menu" slot="reference">
                  <ObjectMenuIcon />
                </div>
              </el-popover>
              <el-tooltip :open-delay="700" effect="dark" :content="item.name" placement="bottom" :offset="2" :visible-arrow="false">
                <span :class="['truncate', 'pointer', 'monitoring__unit-name', 'transition-colors', 'ease-in-out', 'duration-200', { 'text-error': currentUnitIndex === index }]">
                  <span v-if="!addressSwitch && item.terminal && item.terminal.status === 'ACTIVE'" class="font-normal text-xs leading-3 truncate">
                    <el-tooltip :open-delay="700" effect="dark" :content="$t('monitoring.terminal_on')" placement="bottom" :offset="2" :visible-arrow="false">
                      <TerminalOn />
                    </el-tooltip>
                  </span>
                  {{ item.name }}
                  <div class="block">
                    <span v-if="addressSwitch && item.terminal && item.terminal.status === 'ACTIVE'" class="border-r-2 font-normal text-xs leading-3 truncate mr-2 pr-2">
                      <el-tooltip :open-delay="700" effect="dark" :content="$t('monitoring.terminal_on')" placement="bottom" :offset="2" :visible-arrow="false">
                        <TerminalOn />
                      </el-tooltip>
                    </span>
                    <span v-if="addressSwitch" class="font-normal text-xs leading-3 truncate" style="color: #828d9b">
                      {{ item.address }}
                    </span>
                  </div>
                </span>
              </el-tooltip>
            </div>
            <div class="row-icons">
              <div
                :class="[
                  'annotation-icon',
                  {
                    'active-annotation-icon': activeAnnotationObject.id === item.id
                  },
                  { 'annotation-icon--isCamera': isHaveCamera(item) }
                ]"
              >
                <ShowAnnotation v-if="!objectAnnotationSwitch && activeAnnotationObject.id !== item.id" @click.native.stop="showAnnotation(item, index, true)" />
                <ActiveShowAnnotation v-if="!objectAnnotationSwitch && activeAnnotationObject.id === item.id" @click.native.stop="showAnnotation(item, index, true)" />
              </div>
              <div class="camera-icon">
                <CameraActiveIcon v-if="isHaveCamera(item) && isShowCamera && cameraId === item.id && isAnnotationCamera" @click.native.stop="showCamera(item)" />
                <CameraIcon v-else-if="isHaveCamera(item)" @click.native.stop="showCamera(item)" />
              </div>
              <ActiveEyeIcon v-if="vuexActiveEye.includes(item.id)" @click.native="clickEyeObj(item, $event)" class="eye" />
              <EyeIcon v-else @click.native="clickEyeObj(item, $event)" class="eye" />
              <ActiveArrowIcon v-if="vuexActiveArrow.includes(item.id)" @click.native="clickArrowObj(item, $event)" class="object-arrow" />
              <ArrowIcon v-else @click.native="clickArrowObj(item, $event)" class="object-arrow" />

              <MoveGreenIcon v-if="item.status === 10" class="status objects-status" />

              <ParkingIgnitionGreenIcon v-if="item.status === 9" class="status status--ignition" />

              <ParkingGreenIcon v-if="item.status === 8" class="status" />

              <MoveYellowIcon MoveYellowIcon v-if="item.status === 7" class="status" />

              <ParkingIgnitionYellowIcon v-if="item.status === 6" class="status status--ignition" />

              <ParkingYellowIcon v-if="item.status === 5" class="status" />

              <MoveGreyIcon v-if="item.status === 4" class="status" />

              <ParkingIgnitionGreyIcon v-if="item.status === 3" class="status status--ignition" />

              <ParkingGreyIcon v-if="item.status === 2" class="status" />

              <QuestionIcon v-if="item.status === 1" class="status" />
            </div>
          </div>
        </RecycleScroller>
      </el-skeleton>

      <!-- Группы -->
      <template v-if="vuexIsActiveGroup">
        <DynamicScroller class="scroller overflow-y-auto" ref="scroller" id="scroller" :items="groupItems" :min-item-size="48" key-field="group_id">
          <template v-slot="{ item, index, active }">
            <DynamicScrollerItem :item="item" :active="active" :size-dependencies="[item.units]" :data-index="index">
              <div>
                <div class="group-container hover_bg-reallylightblue" :class="{ isOpened: item.isShowGroupList }" @click="showGroupList(index)">
                  <div class="row-name area1">
                    <el-popover v-if="item.group_name !== 'Без группы'" popper-class="monitoring-popover" class="z-30 pointer" placement="bottom-start" trigger="hover">
                      <div class="poppover-container">
                        <span @click="openReportGroup(item, 'update-unit-events')" class="poppover-item">
                          {{ $t('monitoring.to_reports') }}
                        </span>
                        <span class="poppover-item" @click="editGroup(item)">
                          {{ $t('monitoring.groups.edit') }}
                        </span>
                      </div>
                      <div slot="reference" @click.stop.self>
                        <ObjectMenuIcon
                          class="extra-menu"
                          :class="{
                            'group-menu-icon': item.isShowGroupList
                          }"
                        />
                      </div>
                    </el-popover>
                    <span
                      class="pointer monitoring__unit-name monitoring__unit-name-group transition-colors ease-in-out duration-200 uppercase text-sm"
                      :class="{
                        'monitoring__unit-name--without-group': item.group_name === 'Без группы'
                      }"
                    >
                      {{ item.group_name }}
                    </span>
                    <div class="count">
                      <span class="count-group">{{ item.units.length }}</span>
                    </div>
                  </div>
                  <div class="row-icons area2">
                    <ActiveEyeIcon v-if="vuexActiveGroupEye.includes(item.group_id) || vuexIsActiveAllEye" @click.native="clickGroupEyeObj(item, $event)" class="eye" />
                    <EyeIcon v-else @click.native="clickGroupEyeObj(item, $event)" class="eye" />
                    <ActiveArrowIcon v-if="vuexActiveGroupArrow.includes(item.group_id) || vuexIsActiveAllArrow" @click.native="clickGroupArrowObj(item, $event)" class="object-arrow" />
                    <ArrowIcon v-else @click.native="clickGroupArrowObj(item, $event)" class="object-arrow" />
                    <div v-if="vuexIsActiveGroup" :key="item.group_id" :class="['units-group', { 'units-group--active': item.isShowGroupList }]" @click.self="showGroupList(index)">
                      <ArrowDownIcon class="arrow-down" :class="{ 'arrow-down--active': item.isShowGroupList }" />
                    </div>
                    <div v-else class="units">
                      <MoveGreenIcon v-if="item.status === 10" class="status" />
                      <ParkingIgnitionGreenIcon v-if="item.status === 9" class="status status--ignition" />
                      <ParkingGreenIcon v-if="item.status === 8" class="status" />
                      <MoveYellowIcon MoveYellowIcon v-if="item.status === 7" class="status" />
                      <ParkingIgnitionYellowIcon v-if="item.status === 6" class="status status--ignition" />
                      <ParkingYellowIcon v-if="item.status === 5" class="status" />
                      <MoveGreyIcon v-if="item.status === 4" class="status" />
                      <ParkingIgnitionGreyIcon v-if="item.status === 3" class="status status--ignition" />
                      <ParkingGreyIcon v-if="item.status === 2" class="status" />
                      <QuestionIcon v-if="item.status === 1" class="status" />
                    </div>
                  </div>
                </div>
                <ul v-if="item.isShowGroupList" :key="item.group_id" class="area3 group-objects-list pb-5">
                  <div class="linear"></div>
                  <transition-group name="el-fade-in">
                    <li class="group-items hover_bg-reallylightblue row-name" v-for="groupItem in item.units" :key="groupItem.id">
                      <el-popover popper-class="monitoring-popover" class="z-30 pointer" placement="bottom-start" trigger="hover">
                        <div class="poppover-container">
                          <span @click="emitEditForm(groupItem, 'update-unit')" class="poppover-item">
                            {{ $t('monitoring.change-object') }}
                          </span>
                          <div class="poppover-item">
                            <el-popover class="fasttrack" placement="right" trigger="hover">
                              <div class="items-container">
                                <span @click="showFastTrack(groupItem, 'today')" class="poppover-item">
                                  {{ $t('monitoring.fast_tracks.today') }}
                                </span>
                                <span @click="showFastTrack(groupItem, 'yesterday')" class="poppover-item">
                                  {{ $t('monitoring.fast_tracks.yesterday') }}
                                </span>
                                <span @click="showFastTrack(groupItem, 'week')" class="poppover-item">
                                  {{ $t('monitoring.fast_tracks.week') }}
                                </span>
                                <span @click="showFastTrack(groupItem, 'month')" class="poppover-item">
                                  {{ $t('monitoring.fast_tracks.month') }}
                                </span>
                              </div>
                              <div slot="reference" class="title">
                                <span>
                                  {{ $t('monitoring.fast_tracks') }}
                                </span>
                                <span class="fasttrack-icon">
                                  <FastTrackIcon />
                                </span>
                              </div>
                            </el-popover>
                          </div>
                          <span @click="emitEditForm(groupItem, 'update-unit-events')" class="poppover-item">
                            {{ $t('monitoring.create-event') }}
                          </span>
                          <span @click="openReport(groupItem, 'update-unit-events')" class="poppover-item">
                            {{ $t('monitoring.to_reports') }}
                          </span>
                          <span @click="openCommands(groupItem)" class="poppover-item">
                            {{ $t('monitoring.commands') }}
                          </span>
                        </div>
                        <svg class="extra-menu" slot="reference" width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g clip-path="url(#clip0_2265_415)">
                            <path d="M2 4C0.9 4 0 4.9 0 6C0 7.1 0.9 8 2 8C3.1 8 4 7.1 4 6C4 4.9 3.1 4 2 4ZM14 4C12.9 4 12 4.9 12 6C12 7.1 12.9 8 14 8C15.1 8 16 7.1 16 6C16 4.9 15.1 4 14 4ZM8 4C6.9 4 6 4.9 6 6C6 7.1 6.9 8 8 8C9.1 8 10 7.1 10 6C10 4.9 9.1 4 8 4Z" fill="#3C5E8F" />
                          </g>
                          <defs>
                            <clipPath id="clip0_2265_415">
                              <rect width="16" height="12" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </el-popover>
                      <span @click="showAnnotation(groupItem, index)" :class="['group-unit-name', 'pointer', 'monitoring__unit-name', 'transition-colors', 'ease-in-out', 'duration-200', currentUnitIndex === index ? 'text-error' : '']">
                        {{ groupItem.name }}
                        <span v-if="addressSwitch" class="block font-normal text-xs leading-3 truncate" style="color: #828d9b">
                          {{ groupItem.address }}
                        </span>
                      </span>
                      <div class="row-icons">
                        <div
                          class="annotation-icon"
                          :class="{
                            'active-annotation-icon': activeAnnotationObject.id === groupItem.id
                          }"
                        >
                          <ShowAnnotation v-if="!objectAnnotationSwitch && activeAnnotationObject.id !== groupItem.id" @click.native="showAnnotation(groupItem, index, true)" />
                          <ActiveShowAnnotation v-if="!objectAnnotationSwitch && activeAnnotationObject.id === groupItem.id" @click.native="showAnnotation(groupItem, index, true)" />
                        </div>
                        <ActiveEyeIcon v-if="vuexActiveEye.includes(groupItem.id)" @click.native="clickEyeObj(groupItem, $event, null, item)" class="eye" />
                        <EyeIcon v-else @click.native="clickEyeObj(groupItem, $event, null, item)" class="eye" />
                        <ActiveArrowIcon v-if="vuexActiveArrow.includes(groupItem.id)" @click.native="clickArrowObj(groupItem, $event, null, item)" class="object-arrow" />
                        <ArrowIcon v-else @click.native="clickArrowObj(groupItem, $event, null, item)" class="object-arrow" />
                        <MoveGreenIcon v-if="groupItem.status === 10" class="status" />
                        <ParkingIgnitionGreenIcon v-if="groupItem.status === 9" class="status status--ignition" />
                        <ParkingGreenIcon v-if="groupItem.status === 8" class="status" />

                        <MoveYellowIcon MoveYellowIcon v-if="groupItem.status === 7" class="status" />
                        <ParkingIgnitionYellowIcon v-if="groupItem.status === 6" class="status status--ignition" />
                        <ParkingYellowIcon v-if="groupItem.status === 5" class="status" />

                        <MoveGreyIcon v-if="groupItem.status === 4" class="status" />
                        <ParkingIgnitionGreyIcon v-if="groupItem.status === 3" class="status status--ignition" />
                        <ParkingGreyIcon v-if="groupItem.status === 2" class="status" />
                        <QuestionIcon v-if="groupItem.status === 1" class="status" />
                      </div>
                    </li>
                  </transition-group>
                </ul>
              </div>
            </DynamicScrollerItem>
          </template>
        </DynamicScroller>
      </template>
    </div>
    <div class="monitoring__footer">
      <span class="text">
        {{ $t('monitoring.displayed_objects') }}
        {{ searchMonitoringList.length }} /
        {{ monitoringList.length }}
      </span>
      <div class="size-container">
        <span>{{ $t('monitoring.shape_size') }}</span>
        <div class="size-btns-container">
          <div @click="changeSize('small')" :class="['small', 'pointer', { 'active-size': currentSize === 'small' }]"></div>
          <div @click="changeSize('medium')" :class="['medium', 'pointer', { 'active-size': currentSize === 'medium' }]"></div>
          <div @click="changeSize('large')" :class="['large', 'pointer', { 'active-size': currentSize === 'large' }]"></div>
        </div>
      </div>
    </div>
    <transition name="slide-fade" mode="out-in">
      <filters v-if="isFilterActive" :currentFilter="currentFilter" @close="handlFilterClose" />
    </transition>
  </div>
</template>

<script>
// eslint-disable vue/no-side-effects-in-computed-properties -->
import { monitoringFilterApi } from '@/api'
import { getwsUserId } from '@/api/ws.js'
import createMarker from '@/mixins/createMarker.js'
import websocketHandler from '@/mixins/websocketHandler.js'
import getMarkerValues from '@/utils/getMarkerValues.js'
import monitoringHelper from '@/mixins/monitoringHelper.js'
import moment from 'moment'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { toggleOpenedBoxTrack } from '@/services/reports/boxTrackReport'

export default {
  name: 'Monitoring',
  components: {
    SettingsMonitoring: () => import('./components/SettingsMonitoring.vue'),
    SortIconEye: () => import('./assets/sortIcon/SortIconEye.vue'),
    SortIconArrow: () => import('./assets/sortIcon/SortIconArrow.vue'),
    SortIconName: () => import('./assets/sortIcon/SortIconName.vue'),
    SortIconDatepoint: () => import('./assets/sortIcon/SortIconDatepoint.vue'),
    MoveGreenIcon: () => import('./assets/statusIcon/MoveGreenIcon.vue'),
    MoveGreyIcon: () => import('./assets/statusIcon/MoveGreyIcon.vue'),
    MoveYellowIcon: () => import('./assets/statusIcon/MoveYellowIcon.vue'),
    ParkingGreenIcon: () => import('./assets/statusIcon/ParkingGreenIcon.vue'),
    ParkingYellowIcon: () => import('./assets/statusIcon/ParkingYellowIcon.vue'),
    ParkingGreyIcon: () => import('./assets/statusIcon/ParkingGreyIcon.vue'),
    ParkingIgnitionGreenIcon: () => import('./assets/statusIcon/ParkingIgnitionGreenIcon.vue'),
    ParkingIgnitionYellowIcon: () => import('./assets/statusIcon/ParkingIgnitionYellowIcon.vue'),
    ParkingIgnitionGreyIcon: () => import('./assets/statusIcon/ParkingIgnitionGreyIcon.vue'),
    QuestionIcon: () => import('./assets/statusIcon/QuestionIcon.vue'),
    FastTrackIcon: () => import('./assets/FastTrackIcon'),
    FilterIcon: () => import('./assets/FilterIcon.vue'),
    SearchIcon: () => import('./assets/SearchIcon.vue'),
    ReportIcon: () => import('./assets/ReportIcon.vue'),
    EyeIcon: () => import('./assets/eyeIcon/EyeIcon.vue'),
    ActiveEyeIcon: () => import('./assets/eyeIcon/ActiveEyeIcon.vue'),
    AllEyeIcon: () => import('./assets/allEyeIcon/AllEyeIcon.vue'),
    ActiveAllEyeIcon: () => import('./assets/allEyeIcon/ActiveAllEyeIcon.vue'),
    ArrowIcon: () => import('./assets/arrowIcon/ArrowIcon.vue'),
    ActiveArrowIcon: () => import('./assets/arrowIcon/ActiveArrowIcon.vue'),
    AllArrowIcon: () => import('./assets/allArrowIcon/AllArrowIcon.vue'),
    ActiveAllArrowIcon: () => import('./assets/allArrowIcon/ActiveAllArrowIcon.vue'),
    GroupIcon: () => import('./assets/groupIcon/GroupIcon.vue'),
    ActiveGroupIcon: () => import('./assets/groupIcon/ActiveGroupIcon.vue'),
    filters: () => import('./components/filter.vue'),
    ArrowDownIcon: () => import('./assets/ArrowDownIcon.vue'),
    ShowAnnotation: () => import('./assets/allAnnotationIcon/ShowAnnotationIcon.vue'),
    ActiveShowAnnotation: () => import('./assets/allAnnotationIcon/ActiveShowAnnotationIcon.vue'),
    ObjectMenuIcon: () => import('./assets/ObjectMenuIcon.vue'),
    CameraIcon: () => import('./assets/allCameraIcon/CameraIcon.vue'),
    CameraActiveIcon: () => import('./assets/allCameraIcon/CameraActiveIcon.vue'),
    AnnotationCamera: () => import('./components/annotationCamera.vue'),
    SettingIcon: () => import('./assets/SettingIcon.vue'),
    TerminalOn: () => import('./assets/TerminalIcon/TerminalOn.vue')
  },
  mixins: [websocketHandler, createMarker, monitoringHelper],

  props: {
    map: {
      type: Object,
      default: () => {}
    },
    isActiveMonitoringButton: {
      type: Boolean,
      default: false
    },
    fromMonitoring: {
      type: Boolean,
      default: false
    },
    markerGroup: {
      type: Object,
      default: () => {}
    },
    isAnnotationCamera: {
      type: Boolean,
      default: false
    },
    isOpenedAnnotation: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      filterLength: 0,
      addRadiusValue: null,
      clusterRadiusCount: 100,
      isPopoveerSettingsOpen: false,
      isAnnotation: false,
      filterString: '',
      loading: false,
      currentFilter: [
        {
          values: '',
          options: [
            { key: this.$t('monitoring.search.name'), value: 'name' },
            { key: this.$t('monitoring.search.imei'), value: 'imei' },
            {
              key: this.$t('monitoring.search.phonenumber'),
              value: 'phonenumber'
            },
            {
              key: this.$t('monitoring.search.custom_fields'),
              value: 'custom_fields'
            },
            {
              key: this.$t('monitoring.search.sensors_name'),
              value: 'sensors.name'
            },
            {
              key: this.$t('monitoring.search.sensors_types'),
              value: 'sensors.type.key'
            },
            {
              key: this.$t('monitoring.search.sensors_param'),
              value: 'sensors.param'
            },
            { key: this.$t('monitoring.search.events'), value: 'events' },
            { key: this.$t('monitoring.search.driver'), value: 'driver' },
            { key: this.$t('monitoring.search.groups'), value: 'groups' }
          ]
        }
      ],
      currentSize: null,
      sortOrder: '',
      sortName: '',
      list: [],
      isFilterActive: false,
      rowError: [],
      activeEye: [],
      annotationUnit: null,
      currentUnitIndex: null,
      currentWidth: null,
      markersOnMap: {},
      markersUnitOnMap: {},
      activeArrow: [],
      activeEyeLength: null,
      activeArrowLength: null,
      emptyArr: [],
      activeGroupEye: [],
      activeGroupArrow: [],
      newList: [],
      statusInterval: null,
      coords: [],
      objectAnnotationSwitch: true,
      activeAnnotationObject: {},
      isShowCamera: false,
      cameraId: null,
      showDatePicker: false,
      fasttrackDates: null,
      isPickerOpen: false,
      unitForCustomDate: null,
      mainPopoverKey: 0
    }
  },

  computed: {
    ...mapState({
      units: state => state.units.units,
      objectAnnotationSwitchGetter: state => state.units.objectAnnotationSwitch,
      filteredUnits: state => state.units.filteredUnits,
      closeCommand: state => state.commands.closeCommand,
      monitoringListObj: state => state.monitoringNew.monitoringListObj,
      me: state => state.login.me,
      zoom: state => state.map.zoom
    }),

    ...mapGetters({
      monitoringList: 'monitoringNew/monitoringList',
      filterUnits: 'monitoringNew/filtredUnits',
      loadedMarkersOnMap: 'monitoringNew/markersOnMap',
      unitsGroup: 'units/unitsGroup',
      loadedMarkersCluster: 'monitoringNew/loadedMarkersCluster',
      vuexIsActiveAllArrow: 'monitoringNew/vuexIsActiveAllArrow',
      vuexActiveArrow: 'monitoringNew/vuexActiveArrow',
      vuexMarkersOnMap: 'monitoringNew/vuexMarkersOnMap',
      vuexActiveGroupArrow: 'monitoringNew/vuexActiveGroupArrow',
      vuexActiveEyeLength: 'monitoringNew/vuexActiveEyeLength',
      vuexActiveArrowLength: 'monitoringNew/vuexActiveArrowLength',
      vuexIsActiveGroup: 'monitoringNew/vuexIsActiveGroup',
      vuexAnnotationUnit: 'monitoringNew/vuexAnnotationUnit',
      vuexIsAnnotation: 'monitoringNew/vuexIsAnnotation',
      vuexGroupItemsOnMap: 'monitoringNew/vuexGroupItemsOnMap',
      vuexSearchMonitoringListOnMap: 'monitoringNew/vuexSearchMonitoringListOnMap',
      formattedCurrentTime: 'login/formattedCurrentTime',
      addressSwitch: 'monitoringNew/addressSwitch',
      vuexSortName: 'monitoringNew/vuexSortName',
      vuexSortOrder: 'monitoringNew/vuexSortOrder',
      vuexSearchObject: 'monitoringNew/vuexSearchObject',
      vuexTerminalsMonitoring: 'monitoringNew/vuexTerminalsMonitoring',
      getPolyline: 'monitoringNew/getPolyline'
    }),

    ...mapGetters('reports', ['tripsTable', 'defaultReportTemplate']),
    ...mapGetters('map', ['vuexRadiusValue']),

    hasFilters() {
      return this.filterLength && this.currentFilter.length && this.currentFilter[0].values !== ''
    },

    reportTables() {
      return this.$parent.reportTables
    },

    searchMonitoringList() {
      let newList = this.monitoringList.filter(item => {
        return item.name.toLowerCase().includes(this.filterString.toLowerCase().trim()) || item.imei.toLowerCase().includes(this.filterString.toLowerCase().trim())
      })
      if (this.sortOrder === 'asc') {
        if (this.sortName === 'name') {
          newList.sort((a, b) => {
            return a.name.toLowerCase().trim() > b.name.toLowerCase().trim() ? 1 : -1
          })
        } else if (this.sortName === 'datepoint') {
          const arrPoint = newList.filter(el => el.datepoint)
          const arrWithoutPoint = newList.filter(el => !el.datepoint)
          const sortArrPoint = arrPoint.sort((a, b) => b.datepoint - a.datepoint)
          newList = [...sortArrPoint, ...arrWithoutPoint]
        } else if (this.sortName === 'arrow' && this.vuexActiveArrow.length > 0) {
          newList.sort((a, b) => (this.vuexActiveArrow.includes(b.id) ? 1 : -1))
        } else if (this.sortName === 'eye' && this.vuexActiveEye.length > 0) {
          newList.sort((a, b) => (this.vuexActiveEye.includes(b.id) ? 1 : -1))
        }
      } else if (this.sortOrder === 'desc') {
        if (this.sortName === 'name') {
          newList.sort((a, b) => {
            return a.name.toLowerCase > b.name.toLowerCase ? -1 : 1
          })
        } else if (this.sortName === 'datepoint') {
          const arrPoint = newList.filter(el => el.datepoint)
          const arrWithoutPoint = newList.filter(el => !el.datepoint)
          const sortArrPoint = arrPoint.sort((a, b) => a.datepoint - b.datepoint)
          newList = [...sortArrPoint, ...arrWithoutPoint]
        } else if (this.sortName === 'arrow' && this.vuexActiveArrow.length > 0) {
          newList.sort((a, b) => (this.vuexActiveArrow.includes(b.id) ? -1 : 1))
        } else if (this.sortName === 'eye' && this.vuexActiveEye.length > 0) {
          newList.sort((a, b) => (this.vuexActiveEye.includes(b.id) ? -1 : 1))
        }
      }
      return newList
    },

    groupItems() {
      let groups = this.unitsGroup

      groups = groups.map(group => {
        const units = group.units.map(object => {
          return this.monitoringList.find(item => item.id === object.id)
        })
        return {
          group_name: group.name,
          group_id: group.id,
          isShowGroupList: false,
          units
        }
      })

      const groupsUnitsIds = []

      groups.forEach(item => {
        item.units.forEach(item => {
          if (item && item.id) {
            groupsUnitsIds.push(item.id)
          }
        })
      })

      const withoutGroupUnits = this.monitoringList.filter(item => !groupsUnitsIds.includes(item.id))

      groups.push({
        group_name: this.$t('monitoringListTranslater.withoutgroup'),
        group_id: 'no-group',
        isShowGroupList: false,
        units: withoutGroupUnits
      })

      if (groups.length) {
        groups.forEach((group, idx) => {
          if (this.groupsList.length) {
            group.isShowGroupList = this.groupsList[idx].isShowGroupList
            return
          }

          group.isShowGroupList = false
        })
      }

      if (this.sortOrder === 'asc') {
        if (this.sortName === 'name') {
          groups = groups.sort((a, b) => (a.group_name > b.group_name ? 1 : -1))
        } else if (this.sortName === 'datepoint') {
          groups.forEach(item => {
            if (item.isShowGroupList) {
              const arrPoint = item.units.filter(el => el.datepoint)
              const arrWithoutPoint = item.units.filter(el => !el.datepoint)
              const sortArrPoint = arrPoint.sort((a, b) => b.datepoint - a.datepoint)
              item.units = [...sortArrPoint, ...arrWithoutPoint]
            }
          })
        } else if (this.sortName === 'arrow' && this.vuexActiveArrow.length) {
          groups.forEach(item => {
            if (item.isShowGroupList) {
              item.units = item.units.sort((a, b) => (this.vuexActiveArrow.includes(b.id) ? 1 : -1))
            }
          })
        } else if (this.sortName === 'eye' && this.vuexActiveEye.length) {
          groups.forEach(item => {
            if (item.isShowGroupList) {
              item.units = item.units.sort((a, b) => (this.vuexActiveEye.includes(b.id) ? 1 : -1))
            }
          })
        }
      } else if (this.sortOrder === 'desc') {
        if (this.sortName === 'name') {
          groups = groups.sort((a, b) => (a.group_name > b.group_name ? -1 : 1))
        } else if (this.sortName === 'datepoint') {
          groups.forEach(item => {
            if (item.isShowGroupList) {
              const arrPoint = item.units.filter(el => el.datepoint)
              const arrWithoutPoint = item.units.filter(el => !el.datepoint)
              const sortArrPoint = arrPoint.sort((a, b) => a.datepoint - b.datepoint)
              item.units = [...sortArrPoint, ...arrWithoutPoint]
            }
          })
        } else if (this.sortName === 'arrow' && this.vuexActiveArrow.length) {
          groups.forEach(item => {
            if (item.isShowGroupList) {
              item.units = item.units.sort((a, b) => (this.vuexActiveArrow.includes(b.id) ? -1 : 1))
            }
          })
        } else if (this.sortName === 'eye' && this.vuexActiveEye.length) {
          groups.forEach(item => {
            if (item.isShowGroupList) {
              item.units = item.units.sort((a, b) => (this.vuexActiveEye.includes(b.id) ? -1 : 1))
            }
          })
        }
      }
      groups = groups.map(el => {
        el.units = el.units.filter(item => {
          if (item && item.id) {
            return item.name.toLowerCase().includes(this.filterString.toLowerCase())
          }
        })
        return el
      })
      this.groupsList = groups
      return groups
    }
  },
  watch: {
    objectAnnotationSwitchGetter(val) {
      this.objectAnnotationSwitch = val
    },
    vuexRadiusValue(val) {
      this.clusterRadiusCount = val
    },
    isActiveMonitoringButton(val) {
      this.getCoords(val)
    },
    closeCommand() {
      const monitoringContainer = document.getElementById('monitoring')
      monitoringContainer.style.width = `${this.currentWidth}px`
      monitoringContainer.style.maxWidth = ''
    },
    searchMonitoringList() {
      if (this.currentFilter.length && this.currentFilter[0].values) {
        const unitsIds = this.searchMonitoringList.map(item => item.id)
        const filters = { filter: this.currentFilter, units: unitsIds }
        localStorage.setItem(`filtredUnits_${this.me.active_company.id}`, JSON.stringify(filters))
      }

      this.setVuexSearchMonitoringListOnMap(this.searchMonitoringList)
    },
    vuexActiveArrow() {
      if (this.vuexActiveArrow.length > 0) {
        const coords = []
        this.searchMonitoringList.forEach(item => {
          if (!item.latlon || !item.latlon.value) return
          if (this.vuexMarkersOnMap[item.id] && this.vuexMarkersOnMap[item.id].id === item.id && this.vuexActiveArrow.includes(item.id)) {
            if (this.vuexMarkersUnitOnMap[item.id]) {
              this.vuexMarkersUnitOnMap[item.id].setLatLng(item.latlon.value)
              coords.push(item.latlon.value)
            }
          }
        })
        this.$emit('helper-tracking-markers', coords)
        this.$emit('is-active-arrow', true)
      } else {
        this.$emit('is-active-arrow', false)
      }
    },
    '$store.state.monitoringNew.monitoringList': {
      deep: true,
      handler(newVal) {
        if (newVal) {
          this.recalculateStatus(newVal)
        }
      }
    },
    isAnnotationCamera(newVal) {
      if (!newVal) {
        this.isShowCamera = newVal
      }
    },

    isOpenedAnnotation(val) {
      if (val === 'closed') {
        const activeObjectIdx = this.searchMonitoringList.findIndex(item => item.id === this.activeAnnotationObject.id)

        this.showAnnotation(this.activeAnnotationObject, activeObjectIdx, true)
      }
    },
    monitoringList: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.firstLoadMonitoring()
          this.loading = false
        }
      }
    },
    filterString(newVal) {
      this.setVuexSearchObject(newVal)
    }
  },
  methods: {
    ...mapActions({
      getObjectsGroup: 'units/GET_GROUP_UNITS',
      getMapForBoxTrack: 'monitoring/getMapForBoxTrack'
    }),
    ...mapMutations('units', ['SET_OBJECT_ANNOTATION_SWITCH']),

    ...mapMutations({
      setMarkersUnitOnMap: 'monitoringNew/setMarkersUnitOnMap',
      setMarkerCluster: 'monitoringNew/setMarkerCluster',
      addVuexActiveArrow: 'monitoringNew/addVuexActiveArrow',
      addVuexActiveGroupArrow: 'monitoringNew/addVuexActiveGroupArrow',
      setVuexActiveEyeLength: 'monitoringNew/setVuexActiveEyeLength',
      setVuexActiveArrowLength: 'monitoringNew/setVuexActiveArrowLength',
      clearVuexActiveGroupEye: 'monitoringNew/clearVuexActiveGroupEye',
      clearVuexActiveEye: 'monitoringNew/clearVuexActiveEye',
      clearVuexActiveArrow: 'monitoringNew/clearVuexActiveArrow',
      clearVuexMarkersOnMap: 'monitoringNew/clearVuexMarkersOnMap',
      clearVuexMarkersUnitOnMap: 'monitoringNew/clearVuexMarkersUnitOnMap',
      clearVuexActiveGroupArrow: 'monitoringNew/clearVuexActiveGroupArrow',
      setVuexIsActiveGroup: 'monitoringNew/setVuexIsActiveGroup',
      setVuexAnnotationUnit: 'monitoringNew/setVuexAnnotationUnit',
      setVuexIsAnnotation: 'monitoringNew/setVuexIsAnnotation',
      setVuexGroupItemsOnMap: 'monitoringNew/setVuexGroupItemsOnMap',
      setVuexSearchMonitoringListOnMap: 'monitoringNew/setVuexSearchMonitoringListOnMap',
      SET_FILTRED_UNITS: 'monitoringNew/SET_FILTRED_UNITS',
      // setAddressSwitch: 'monitoringNew/setAddressSwitch',
      setVuexSortName: 'monitoringNew/setVuexSortName',
      setVuexSortOrder: 'monitoringNew/setVuexSortOrder',
      setVuexSearchObject: 'monitoringNew/setVuexSearchObject',
      SET_RADIUS_VALUE: 'map/SET_RADIUS_VALUE'
    }),

    /**
     * Очистка кэша BoxTrack
     * */
    clearCashBoxTrack() {
      this.$store.commit('monitoringNew/clearCashBoxTrack')
    },

    /**
     * Toggles the picker and sets the unitForCustomDate to the provided item.
     *  this.unitForCustomDate because el-picker select another unit (propagation i guess).
     * @param {Object} item - The item to set as unitForCustomDate.
     */
    togglePicker(item) {
      this.unitForCustomDate = item
      this.isPickerOpen = true
      this.openPicker()
    },

    /**
     * Opens the picker after the next tick.
     * This is needed because we dont show the input of the dates(we want to show the datepicker directly).
     */
    openPicker() {
      this.isPickerOpen = true
      this.$nextTick(() => {
        this.$refs.picker.showPicker()
      })
    },

    showBoxTrackInMap() {
      const showBoxTrack = true
      this.$store.commit('monitoringNew/toggleOpenedBoxTrack', showBoxTrack)
    },

    changeClusterRadius(value) {
      if (value && +this.clusterRadiusCount > 10) {
        this.clusterRadiusCount -= 10
      } else if (!value && +this.clusterRadiusCount < 100) {
        this.clusterRadiusCount += 10
      }
      console.log('herere')
      localStorage.setItem('clusterRadiusCount', this.clusterRadiusCount)

      this.SET_RADIUS_VALUE(this.clusterRadiusCount)
    },

    getCoords(val = true) {
      if (val) {
        this.coords = []
        this.searchMonitoringList.forEach(item => {
          if (!item.latlon) return
          if (this.vuexMarkersOnMap[item.id] && this.vuexMarkersOnMap[item.id].id === item.id && this.vuexActiveArrow.includes(item.id)) {
            this.vuexMarkersUnitOnMap[item.id].setLatLng(item.latlon.value)
            this.coords.push(item.latlon.value)
          }
        })

        this.$emit('helper-tracking-markers', this.coords)
      }
    },

    recalculateStatus(array) {
      const coords = []
      const markerMap = {}

      array.forEach(item => {
        markerMap[item.unit_id] = item
      })

      this.searchMonitoringList.forEach(el => {
        const currentMarkerId = el.id
        const updatedMarker = markerMap[currentMarkerId]
        const isSameMarkerId = updatedMarker && updatedMarker.unit_id === currentMarkerId

        if (isSameMarkerId) {
          if (!el.latlon || !Array.isArray(el.latlon.value)) {
            return {}
          }
          const { latlon: updatedLatlon, angle: updatedAngle, datepoint: updatedDatepoint, datepointHuman: updatedDatepointHuman, ignition: updatedIgnition, speed: updatedSpeed, status: updatedStatus } = getMarkerValues(updatedMarker, this.formattedCurrentTime)

          const isSameLatlon = updatedLatlon.value[0] === el.latlon.value[0] && updatedLatlon.value[1] === el.latlon.value[1]
          const isSameAngle = updatedAngle === el.angle ? el.angle : ''
          const isSameStatus = updatedStatus === el.status ? el.status : ''

          el.angle = updatedAngle
          el.datepoint = updatedDatepoint
          el.datepointHuman = updatedDatepointHuman
          el.ignition = updatedIgnition || ''
          el.speed = updatedSpeed
          el.status = updatedStatus
          el.latlon = updatedLatlon || []

          if (this.vuexMarkersOnMap[currentMarkerId] && this.vuexMarkersOnMap[currentMarkerId].id === currentMarkerId) {
            // обновление позиции маркера на карте
            this.vuexMarkersUnitOnMap[currentMarkerId].setLatLng(el.latlon.value)
            // обновление стрелочки у иконки
            const icons = document.querySelector('.skif-marker__arrow-box')
            // текущая скорость объекта
            const unitSpeedNow = el?.columns.find(column => column.key === 'speed')
            // скорость при которой считаем что объект находится в движении
            const minSpeedOption = el?.custom_fields.find(field => field.key === 'tripdetector_minmovespeed')
            // поворот маркера
            if (icons && el.angle !== -1 && icons.id === `unit_id_${currentMarkerId}`) {
              icons.style.transform = `rotate(${el.angle}deg)`
              // icons.style.display = 'block';
              const arrowElement = document.getElementById(icons.id)

              if (unitSpeedNow.value < minSpeedOption.value && arrowElement) {
                arrowElement.style.display = 'none'
              } else {
                arrowElement.style.display = 'block'
              }
            }

            if (this.isActiveMonitoringButton && this.vuexActiveArrow.includes(currentMarkerId)) {
              coords.push(updatedLatlon.value)
            }
          }
        }

        return el // Возвращаем измененный элемент или элемент без изменений
      })

      this.$emit('helper-tracking-markers', coords)
    },

    editGroup(item) {
      this.$emit('edit-group', item)
    },

    showGroupList(index) {
      this.groupItems[index].isShowGroupList = !this.groupItems[index].isShowGroupList
    },

    openCommands(unit) {
      const monitoringContainer = document.getElementById('monitoring')
      this.currentWidth = monitoringContainer.offsetWidth
      monitoringContainer.style.maxWidth = '500px'
      this.$emit('open-commands', unit)
    },
    changeObjectAnnotationSwitch(value) {
      if (value) this.activeAnnotationObject = {}

      // this.SET_OBJECT_ANNOTATION_SWITCH(this.objectAnnotationSwitch)
    },
    showAnnotation(unit, index, isAnnotation) {
      if (!unit.columns) {
        // if there is no columns set currentindex = index to add error class then remove it after a timeout
        this.currentUnitIndex = index
        setTimeout(() => {
          this.currentUnitIndex = null
        }, 500)

        return
      }

      if (isAnnotation) {
        if (this.activeAnnotationObject.id === unit.id) {
          if (!this.manuallyActivatedUnits.includes(unit.id)) {
            this.markerGroupUnits.removeLayer(this.vuexMarkersUnitOnMap[unit.id])
            this.deleteVuexMarkersOnMap(unit)
            this.deleteVuexMarkerUnitOnMap(unit)
            this.deleteVuexActiveEye({ id: this.activeAnnotationObject.id })
          }

          this.setVuexIsAnnotation(false)
          this.setVuexAnnotationUnit({})
          this.activeAnnotationObject = {}

          return
        }

        if (this.activeAnnotationObject.id && !this.manuallyActivatedUnits.includes(this.activeAnnotationObject.id)) {
          this.markerGroupUnits.removeLayer(this.vuexMarkersUnitOnMap[this.activeAnnotationObject.id])
          this.deleteVuexMarkersOnMap(this.activeAnnotationObject)
          this.deleteVuexMarkerUnitOnMap(this.activeAnnotationObject)
          this.deleteVuexActiveEye({ id: this.activeAnnotationObject.id })
        }

        this.activeAnnotationObject = unit

        this.setVuexIsAnnotation(true)
        this.setVuexAnnotationUnit(unit)
      } else if (this.activeAnnotationObject.id !== unit.id) {
        this.setVuexIsAnnotation(false)
        this.activeAnnotationObject = {}
      }

      if (this.objectAnnotationSwitch) {
        this.setVuexIsAnnotation(true)
        this.setVuexAnnotationUnit(unit)
      }

      this.$emit('open-annotaton', 'opened')
      this.getCenterOnObj(unit)
    },

    setLocalStorageFilteredUnits(filter) {
      // CHECK IF FILTER EXISTS, BECAUSE WE HAVE ONE EMPTY FILTER FOR SELECTION BY DEFAULT
      if (!filter.length || !filter[0].values) {
        localStorage.setItem(`filtredUnits_${this.me.active_company.id}`, JSON.stringify({}))

        return
      }

      const unitsIds = []
      this.searchMonitoringList.forEach(unit => unitsIds.push(unit.id))

      const filters = { filter, units: unitsIds }

      localStorage.setItem(`filtredUnits_${this.me.active_company.id}`, JSON.stringify(filters))
    },

    handlFilterClose(filter) {
      if (!filter.length) {
        this.isFilterActive = false
        // чтобы текущий фильтр не сбрасывался
        // this.currentFilter = []
        localStorage.setItem(`filtredUnits_${this.me.active_company.id}`, JSON.stringify({}))
        return
      }
      this.filterLength = filter.length
      this.currentFilter = filter
      setTimeout(() => {
        const unitsIds = []
        this.searchMonitoringList.forEach(unit => unitsIds.push(unit.id))
        const filters = { filter: this.currentFilter, units: unitsIds }
        localStorage.setItem(`filtredUnits_${this.me.active_company.id}`, JSON.stringify(filters))
      }, 500)

      this.isFilterActive = false
    },
    showCustomFastTrack() {
      this.showFastTrack(this.unitForCustomDate, 'custom')
    },

    showFastTrack(unit, period) {
      // increment popover key to force re-render and close it
      this.mainPopoverKey++

      toggleOpenedBoxTrack(false)

      this.$store.commit('map/EYE_TRIGGER', 'tracks')

      this.getMapForBoxTrack(this.map)

      this.clearCashBoxTrack()

      const dates = this.defaultValueDate(period)

      const formData = {
        zoom: 14,
        units: [{ id: unit.id }],
        from: dates[0],
        to: dates[1]
      }
      this.$store
        .dispatch('monitoring/GET_BOX_TRACK', formData)
        .then(response => {
          this.$emit('showLegend', {
            isShowLegend: true,
            unitName: unit.name,
            sum_distance: response.sum_distance
          })

          this.showBoxTrackInMap()

          this.map.fitBounds(this.getPolyline.getBounds(), {
            paddingTopLeft: [500, 0],
            maxZoom: 20
          })

          // для дебага
          // const boundsTrack = makeBoxPoints(this.map)
          // const BoundsIncrease = makeBoxPointsIncrease(boxPointsWindow)
          // L.rectangle(boundsTrack, {color: "#ff7800", weight: 1}).addTo(this.map);
          // L.rectangle(BoundsIncrease, {color: "red", weight: 2}).addTo(this.map);
        })
        .catch(() => {
          this.rowError.push(unit.id)
          setTimeout(() => {
            this.rowError.splice(this.rowError.indexOf(unit.id), 1)
          }, 500)
        })
    },

    activateFilter() {
      this.isFilterActive = !this.isFilterActive
    },

    swapToReports() {
      this.saveFilters()
      this.$emit('to-reports', { fromMonitoring: true })
    },

    changeSize(size) {
      const container = document.getElementById('monitoring')
      container.addEventListener('resize', () => {
        this.currentSize = ''
      })
      if (size === 'small') {
        container.style.width = '500px'
        this.currentSize = 'small'
      } else if (size === 'medium') {
        container.style.width = '600px'
        this.currentSize = 'medium'
      } else {
        container.style.width = '800px'
        this.currentSize = 'large'
      }
      container.style.transition = ' width 0.7s 0s ease'
      // add timeout to remove transition because its not smooth when we manually resize the window
      setTimeout(() => {
        container.style.transition = 'none'
      }, 1000)
    },

    saveFilters() {
      this.setLocalStorageFilteredUnits(this.currentFilter)
    },

    handlClose() {
      console.log('Handler close report')
      this.$emit('close')
      this.$emit('last-coords', this.coords)
    },

    emitEditForm(unit, event) {
      this.$emit(event, { unitId: unit.id, fromModule: 'monitoring' })
    },

    defaultValueDate(period) {
      let currentDates
      const startDate = moment().set({ hour: 0, minute: 0, second: 0 }).format('YYYY-MM-DD HH:mm:ss')
      let endDate
      switch (period) {
        case 'today':
          endDate = moment(startDate).add(1, 'days').format('YYYY-MM-DD HH:mm:ss')
          currentDates = [startDate, endDate]
          break
        case 'yesterday':
          endDate = moment(startDate).subtract(1, 'days').format('YYYY-MM-DD HH:mm:ss')
          currentDates = [endDate, startDate]
          break
        case 'week':
          endDate = moment(startDate).subtract(7, 'days').format('YYYY-MM-DD HH:mm:ss')
          currentDates = [endDate, startDate]
          break
        case 'month':
          endDate = moment(startDate).subtract(30, 'days').format('YYYY-MM-DD HH:mm:ss')
          currentDates = [endDate, startDate]
          break
        case 'custom':
          currentDates = [this.fasttrackDates[0], this.fasttrackDates[1]]
          break
        default:
          break
      }

      return currentDates
    },

    openReport(unit) {
      const reportForm = JSON.parse(localStorage.getItem(`reportsForm${this.me.id}${this.me.active_company.id}`))

      let [dateStart, dateEnd] = this.defaultValueDate('today')

      if (reportForm && reportForm.datePicker) {
        ;[dateStart, dateEnd] = [reportForm.datePicker[0], reportForm.datePicker[1]]
      }

      const reportId = this.tripsTable.id + unit.id + dateStart + dateEnd
      if (this.reportTables.some(x => x.reportId === reportId) || this.reportTables.length > 3) {
        this.rowError.push(unit.id)
        setTimeout(() => {
          this.rowError.splice(this.rowError.indexOf(unit.id), 1)
        }, 500)
      } else {
        this.$emit('open-report')

        localStorage.setItem(
          `reportsForm${this.me.id}${this.me.active_company.id}`,
          JSON.stringify({
            object: 'base',
            unitsId: unit.id,
            // datePicker: [dateStart, dateEnd],
            reportId,
            isMonitoring: false,
            isFromMonitoring: true
          })
        )
      }
    },

    openReportGroup(unit) {
      const [dateStart, dateEnd] = this.defaultValueDate('today')
      const reportId = this.tripsTable.id + unit.group_id + dateStart + dateEnd
      if (this.reportTables.some(x => x.reportId === reportId) || this.reportTables.length > 3) {
        this.rowError.push(unit.id)
        setTimeout(() => {
          this.rowError.splice(this.rowError.indexOf(unit.id), 1)
        }, 500)
      } else {
        this.$emit('open-report')

        localStorage.setItem(
          `reportsForm${this.me.id}${this.me.active_company.id}`,
          JSON.stringify({
            unitsId: unit.group_id,
            groupUnit: unit.group_id,
            object: 'group',
            datePicker: [dateStart, dateEnd],
            reportId,
            isMonitoring: false,
            isFromMonitoring: true
          })
        )
      }
    },

    sortChange(sortName) {
      if (this.sortName === sortName) {
        if (this.sortOrder === 'asc') {
          this.sortOrder = 'desc'
          this.setVuexSortOrder(this.sortOrder)
        } else if (this.sortOrder === 'desc') {
          this.sortOrder = ''
          this.sortName = ''
          this.setVuexSortName(this.sortName)
          this.setVuexSortOrder(this.sortOrder)
        } else {
          this.sortOrder = 'asc'
          this.setVuexSortOrder(this.sortOrder)
        }
      } else {
        this.sortName = sortName
        this.sortOrder = 'asc'
        this.setVuexSortName(this.sortName)
        this.setVuexSortOrder(this.sortOrder)
      }
      this.helperSaveLocalStorageNew()
    },

    getCenterOnObj(item) {
      if (!item.latlon) return
      this.map.setView(item.latlon.value, this.map.getZoom())
      if (!this.vuexActiveEye.includes(item.id)) {
        this.addVuexActiveEye(item.id)
        this.addVuexMarkersOnMap({ id: item.id, unit: item })
        this.addVuexMarkersUnitOnMap({
          id: item.id,
          unit: this.helperGetMarker(item, item.id)
        })
        this.markerGroupUnits.addLayer(this.vuexMarkersUnitOnMap[item.id])
      } else {
        this.manuallyActivatedUnits.push(item.id)
      }
    },

    saveLocalStorageIsActiveAllIcon(storageName, item) {
      localStorage.setItem(storageName, item)
    },

    clickGroupEyeObj(item, event) {
      const allNoData = item.units.every(el => !el.latlon)

      if (allNoData) return

      // если нет общего глазика в группе - добавим
      if (!this.vuexActiveGroupEye.includes(item.group_id)) {
        this.addVuexActiveGroupEye(item.group_id)
      } else {
        if (this.vuexActiveGroupArrow.includes(item.group_id)) {
          this.deleteVuexActiveGroupArrow(item)
        }
        // если есть то удалим
        this.deleteVuexActiveGroupEye(item)
      }

      // если группы активны
      if (this.isActiveGroup) {
        item.units.forEach(el => {
          this.clickEyeObj(el, event, this.vuexActiveGroupEye.includes(item.group_id), true)
        })
      }

      this.helperSaveLocalStorageNew()

      // CHECK if units in other groups is the same and turn off that group Eye
      // если юниты в других группах одинаковые и выкл групповой глазик

      // this.vuexActiveGroupEye.forEach((groupId) => {
      //   this.groupsList.forEach((group) => {
      //     if (!group.units.length) return

      //     if (group.group_id === groupId) {

      //       const unitsWithData = group.units.filter((el) => el.latlon)
      //       const isAllActive = unitsWithData.every((el) =>
      //         this.vuexActiveEye.includes(el.id)
      //       )

      //       if (!isAllActive) this.deleteVuexActiveGroupEye(group)
      //     }
      //   })
      // })

      // CHECK if units in other groups is the same and turn on that group Eye
      // если юниты в других группах одинаковы и групповой глазик вкл
      // this.groupsList.forEach((group) => {
      //   if (!group.units.length) return
      //   const unitsWithData = group.units.filter((el) => el.latlon)

      //   if (unitsWithData.length) {
      //     const isAllActive = unitsWithData.every((el) =>
      //       this.vuexActiveEye.includes(el.id)
      //     )

      //     if (isAllActive) this.addVuexActiveGroupEye(group.group_id)
      //   }
      // })
    },

    clickGroupArrowObj(item, event) {
      const allNoData = item.units.every(el => !el.latlon)

      if (allNoData) return

      if (!this.vuexActiveGroupArrow.includes(item.group_id)) {
        this.addVuexActiveGroupArrow(item.group_id)
        if (!this.vuexActiveGroupEye.includes(item.id)) {
          this.addVuexActiveGroupEye(item.group_id)
        }
      } else {
        this.deleteVuexActiveGroupArrow(item)
      }

      if (this.isActiveGroup) {
        item.units.forEach(el => {
          this.clickArrowObj(el, event, this.vuexActiveGroupArrow.includes(item.group_id), true)
        })
      }

      this.helperSaveLocalStorageNew()

      // CHECK if units in other groups is the same and turn off that group Arrow
      // this.vuexActiveGroupArrow.forEach((groupId) => {
      //   this.groupsList.forEach((group) => {
      //     if (!group.units.length) return

      //     if (group.group_id === groupId) {
      //       const unitsWithData = group.units.filter((el) => el.latlon)
      //       const isAllActive = unitsWithData.every((el) =>
      //         this.vuexActiveArrow.includes(el.id)
      //       )

      //       if (!isAllActive) this.deleteVuexActiveGroupArrow(group)
      //     }
      //   })
      // })

      // CHECK if units in other groups is the same and turn on that group Arrow
      // this.groupsList.forEach((group) => {
      //   if (!group.units.length) return
      //   const unitsWithData = group.units.filter((el) => el.latlon)

      //   if (unitsWithData.length) {
      //     const isAllActive = unitsWithData.every((el) =>
      //       this.vuexActiveArrow.includes(el.id)
      //     )

      //     if (isAllActive) {
      //       this.addVuexActiveGroupEye(group.group_id)
      //       this.addVuexActiveGroupArrow(group.group_id)
      //     }
      //   }
      // })
    },

    addLayer(unit) {
      this.$emit('add-layer-cluster', unit)
    },

    showAllEye() {
      const isGroupingObjectsStorage = JSON.parse(localStorage.getItem('groupingObjects'))

      this.isActiveAllEye = !this.isActiveAllEye
      this.setVuexIsActiveAllEye(this.isActiveAllEye)
      if (this.vuexIsActiveAllEye) {
        if (this.vuexIsActiveGroup) {
          this.groupItems.forEach(el => {
            if (el.units.length) {
              const unitsWithData = el.units.filter(el => el.latlon)

              if (unitsWithData.length) this.addVuexActiveGroupEye(el.group_id)
            }
          })
        }
        if (this.vuexActiveEye.length === 0) {
          this.searchMonitoringList.forEach(item => {
            if (!item.latlon || !item.latlon.value) return
            this.addVuexActiveEye(item.id)
            this.addVuexMarkersOnMap({ id: item.id, unit: item })
            this.addVuexMarkersUnitOnMap({
              id: item.id,
              unit: this.helperGetMarker(item, item.id)
            })

            if (this.vuexMarkersUnitOnMap[item.id]) {
              if (isGroupingObjectsStorage) {
                this.markerGroupUnits.addLayer(this.vuexMarkersUnitOnMap[item.id])
              } else {
                this.markerGroupUnits.addLayer(this.vuexMarkersUnitOnMap[item.id])
              }
            }
          })
        } else {
          this.searchMonitoringList.forEach(item => {
            if (!item.latlon) return
            if (item.latlon && !this.vuexMarkersOnMap[item.id]) {
              this.addVuexActiveEye(item.id)
              this.addVuexMarkersUnitOnMap({
                id: item.id,
                unit: this.helperGetMarker(item, item.id)
              })
              if (this.vuexMarkersUnitOnMap[item.id]) {
                this.markerGroupUnits.addLayer(this.vuexMarkersUnitOnMap[item.id])
              }
            }
          })
        }

        this.setVuexActiveEyeLength(this.vuexActiveEye.length)
        this.setVuexActiveArrowLength(this.vuexActiveArrow.length)
        this.helperSaveLocalStorageNew()
      } else {
        this.clearVuexActiveGroupEye()
        this.clearVuexActiveGroupArrow()
        this.markerGroupUnits.clearLayers()
        this.markerGroupUnits.eachLayer(layer => this.markerGroupUnits.removeLayer(layer))
        this.clearVuexActiveEye()
        this.clearVuexMarkersOnMap()
        this.clearVuexMarkersUnitOnMap()
        if (this.vuexActiveArrow.length > 0) {
          this.isActiveAllArrow = false
          this.setVuexIsActiveAllArrow(false)
          this.clearVuexActiveArrow()
        }
        this.helperSaveLocalStorageNew()
      }
    },

    showAllArrow() {
      const isGroupingObjectsStorage = JSON.parse(localStorage.getItem('groupingObjects'))
      this.isActiveAllArrow = !this.isActiveAllArrow
      this.setVuexIsActiveAllArrow(this.isActiveAllArrow)
      this.saveLocalStorageIsActiveAllIcon('isActiveAllEye', this.vuexIsActiveAllEye)
      this.saveLocalStorageIsActiveAllIcon('isActiveAllArrow', this.vuexIsActiveAllArrow)
      if (this.vuexIsActiveAllArrow) {
        this.$emit('monitoring-manipulation', true)
        if (this.vuexIsActiveGroup) {
          this.groupItems.forEach(el => {
            if (el.units.length) {
              const unitsWithData = el.units.filter(el => el.latlon)

              if (unitsWithData.length) {
                this.addVuexActiveGroupEye(el.group_id)
                this.addVuexActiveGroupArrow(el.group_id)
              }
            }
          })
        }
        if (this.vuexActiveArrow.length === 0) {
          if (this.vuexIsActiveAllEye) {
            this.searchMonitoringList.forEach(item => {
              if (!item.latlon) return
              if (item.latlon && !this.vuexMarkersOnMap[item.id]) {
                this.addVuexActiveEye(item.id)
                this.addVuexMarkersOnMap({ id: item.id, unit: item })
                this.addVuexMarkersUnitOnMap({
                  id: item.id,
                  unit: this.helperGetMarker(item)
                })
                if (this.vuexMarkersUnitOnMap[item.id]) {
                  if (isGroupingObjectsStorage) {
                    this.markerGroupUnits.addLayer(this.vuexMarkersUnitOnMap[item.id])
                  } else {
                    this.markerGroupUnits.addLayer(this.vuexMarkersUnitOnMap[item.id])
                  }
                }
              }
              this.addVuexActiveArrow(item.id)
            })
          } else {
            this.searchMonitoringList.forEach(item => {
              if (!item.latlon) return
              if (item.latlon && !this.vuexMarkersOnMap[item.id]) {
                this.addVuexMarkersOnMap({ id: item.id, unit: item })
                this.addVuexMarkersUnitOnMap({
                  id: item.id,
                  unit: this.helperGetMarker(item)
                })
                if (!item.latlon.value) return
                if (this.vuexMarkersUnitOnMap[item.id]) {
                  this.markerGroupUnits.addLayer(this.vuexMarkersUnitOnMap[item.id])
                }
              }
              this.addVuexActiveEye(item.id)
              this.addVuexActiveArrow(item.id)
            })
          }
        } else {
          this.searchMonitoringList.forEach(item => {
            if (!item.latlon) return
            if (item.latlon && !this.vuexMarkersOnMap[item.id]) {
              this.addVuexActiveArrow(item.id)
              this.addVuexActiveEye(item.id)
              this.addVuexMarkersOnMap({ id: item.id, unit: item })
              this.addVuexMarkersUnitOnMap({
                id: item.id,
                unit: this.helperGetMarker(item)
              })
              this.markerGroupUnits.addLayer(this.vuexMarkersUnitOnMap[item.id])
            }
          })
        }
        if (!this.vuexIsActiveAllEye) {
          this.isActiveAllEye = true
          this.setVuexIsActiveAllEye(true)
        }
        this.setVuexActiveEyeLength(this.vuexActiveEye.length)
        this.setVuexActiveArrowLength(this.vuexActiveArrow.length)

        this.helperSaveLocalStorageNew()
      } else {
        this.clearVuexActiveGroupArrow()

        this.clearVuexActiveArrow()
        this.setVuexIsActiveAllArrow(false)

        this.saveLocalStorageIsActiveAllIcon('isActiveAllEye', this.vuexIsActiveAllEye)
        this.saveLocalStorageIsActiveAllIcon('isActiveAllArrow', this.vuexIsActiveAllArrow)

        this.helperSaveLocalStorageNew()
      }
    },

    showGroup() {
      this.isActiveGroup = !this.isActiveGroup
      this.setVuexIsActiveGroup(this.isActiveGroup)
      this.$emit('from-monitoring', this.vuexIsActiveGroup)
    },

    showCamera(item) {
      this.cameraId = item.id
      this.isShowCamera = !this.isShowCamera
      if (this.isShowCamera) {
        this.$emit('open-annotation-camera', item)
      } else {
        this.$emit('close-annotation-camera')
      }
    },

    isHaveCamera(item) {
      if (item.custom_fields && item.custom_fields.length > 0) {
        const cameraField = item.custom_fields.find(el => el.key === 'video_cameras')
        if (cameraField && cameraField.value) {
          return true
        }
      }
      return false
    },

    firstLoadMonitoring() {
      // текущая компания
      const activeCompanies = JSON.parse(localStorage.getItem('activeCompanies'))
      if (activeCompanies) {
        const activeCompanyIndex = activeCompanies.findIndex(companyObj => {
          return companyObj.hasOwnProperty(this.me.active_company_id)
        })

        if (activeCompanyIndex !== -1) {
          const existingCompany = activeCompanies[activeCompanyIndex]
          const objEyes = {}
          const objArrows = {}

          // если общий глазик
          if (existingCompany[this.me.active_company_id].isActiveAllEye) {
            this.setVuexIsActiveAllEye(existingCompany[this.me.active_company_id].isActiveAllEye)
            this.isActiveAllEye = existingCompany[this.me.active_company_id].isActiveAllEye
          }

          // если общая стрелочка
          if (existingCompany[this.me.active_company_id].isActiveAllArrow) {
            this.setVuexIsActiveAllArrow(existingCompany[this.me.active_company_id].isActiveAllArrow)
            this.isActiveAllArrow = existingCompany[this.me.active_company_id].isActiveAllArrow
          }

          // если нет обьектов в сторе - заполянем
          if (existingCompany[this.me.active_company_id] && existingCompany[this.me.active_company_id].itemsActiveEye && existingCompany[this.me.active_company_id].itemsActiveEye.length && !this.vuexActiveEye.length) {
            existingCompany[this.me.active_company_id].itemsActiveEye.forEach(key => {
              objEyes[key] = key
            })

            // прокликиваем чтобы отразились в списке и на карте
            this.searchMonitoringList.forEach(item => {
              if (item.id === objEyes[item.id]) {
                this.clickEyeObj(item)
              }
            })
          }

          // тоже самое со стрелочками
          if (existingCompany[this.me.active_company_id] && existingCompany[this.me.active_company_id].itemsActiveArrow && existingCompany[this.me.active_company_id].itemsActiveArrow.length && !this.vuexActiveArrow.length) {
            existingCompany[this.me.active_company_id].itemsActiveArrow.forEach(key => {
              objArrows[key] = key
            })
            this.searchMonitoringList.forEach(item => {
              if (item.id === objArrows[item.id]) {
                this.clickArrowObj(item)
              }
            })
          }

          if (existingCompany[this.me.active_company_id] && existingCompany[this.me.active_company_id].activeGroupArrow && existingCompany[this.me.active_company_id].activeGroupArrow.length) {
            for (const item of existingCompany[this.me.active_company_id].activeGroupArrow) {
              if (!this.vuexActiveGroupArrow.includes(item)) {
                this.addVuexActiveGroupArrow(item)
              }
            }
          }

          if (existingCompany[this.me.active_company_id] && existingCompany[this.me.active_company_id].activeGroupEye && existingCompany[this.me.active_company_id].activeGroupEye.length) {
            for (const item of existingCompany[this.me.active_company_id].activeGroupEye) {
              if (!this.vuexActiveGroupEye.includes(item)) {
                this.addVuexActiveGroupEye(item)
              }
            }
          }

          if (existingCompany[this.me.active_company_id] && existingCompany[this.me.active_company_id].sortOrder) {
            this.setVuexSortOrder(existingCompany[this.me.active_company_id].sortOrder)
          }

          if (existingCompany[this.me.active_company_id] && existingCompany[this.me.active_company_id].sortName) {
            this.setVuexSortName(existingCompany[this.me.active_company_id].sortName)
          }

          // нужно когда переключили на другую компанию
          // список в searchMonitoringList меняется
          this.searchMonitoringList.forEach(item => {
            if (this.vuexMarkersOnMap[item.id] && this.vuexMarkersOnMap[item.id].id === item.id) {
              if (!this.vuexActiveEye.includes(item.id)) {
                this.addVuexActiveEye(item.id)
              }
              if (this.isActiveAllArrow && !this.vuexActiveArrow.includes(item.id)) {
                this.addVuexActiveArrow(item.id)
              }
            }
          })
        }
      }
    },

    showSettingsMonitoring() {
      this.$refs.popover.show()
    }
  },

  beforeDestroy() {
    const items = []
    for (const [key] of Object.entries(this.vuexMarkersOnMap)) {
      items.push(key)
    }
    localStorage.setItem('monitoringMarkers', JSON.stringify(items))

    clearInterval(this.statusInterval)
  },

  async mounted() {
    this.loading = true
    // запрос~ нужен для применения фильтра в мониторинге
    this.isActiveGroup = JSON.parse(localStorage.getItem('isActiveGroup'))

    if (!this.isActiveGroup) this.isActiveGroup = false

    const clusterRadiusCount = JSON.parse(localStorage.getItem('clusterRadiusCount'))

    if (clusterRadiusCount) {
      this.clusterRadiusCount = Number(clusterRadiusCount)
    }

    this.objectAnnotationSwitch = this.objectAnnotationSwitchGetter

    const filters = JSON.parse(localStorage.getItem(`filtredUnits_${this.me.active_company.id}`))

    if (filters && filters.filter && filters.filter.length && filters.units && filters.units.length && filters.filter[0].values.length) {
      this.currentFilter = filters.filter
      this.filterLength = this.currentFilter.length

      this.$store.dispatch('monitoringNew/SET_FILTRED_UNITS', filters.units)
    }

    this.getObjectsGroup()

    this.setVuexSearchMonitoringListOnMap(this.searchMonitoringList)

    if (this.currentFilter.length && !this.currentFilter[0].values) {
      let id = getwsUserId()
      if (id) {
        monitoringFilterApi.monitoring_filter(id, { conditions: [] })
      }
    }

    if (this.vuexSortName && this.vuexSortOrder) {
      this.sortOrder = this.vuexSortOrder
      this.sortName = this.vuexSortName
    }

    if (this.vuexSearchObject) {
      this.filterString = this.vuexSearchObject
    }
    const isGroupingObjects = JSON.parse(localStorage.getItem('groupingObjects'))
    // показываем обьекты, если в кластере то радиус кластера ставим clusterRadiusCount
    // иначе ставим 0, тогда на карте нет кластеров
    setTimeout(() => {
      if (!isGroupingObjects) {
        this.SET_RADIUS_VALUE(0)
      } else {
        this.SET_RADIUS_VALUE(10)
      }
    }, 100)
  }
}
</script>

<style lang="stylus">
.custom-picker
  display none

.fasttrack-datepicker
  left: 150px !important
  top: 150px !important

.repair-icon
  position absolute
  bottom -10px
  right -10px


.monitoring-popover ,  .monitoring-popover-reports
  margin 0 !important
  padding 0 !important
  & .poppover-item
      padding 20px  15px  !important

.monitoring-popover-reports
  & .poppover-item
    padding 10px  0 5px 10px  !important
.settings-popover
  left  39px !important
  p
    line-height: 120%;
    color: #292929;
    font-style: normal;
    font-weight: 600;
    margin-bottom 14px
    font-family: 'Source Sans Pro';
    font-size: 20px;
    cursor pointer
</style>

<style lang="stylus" scoped>


.monitoring__closeIcon
  font-size 24px
  font-weight 600
  margin-left 20px
.free-resize
  left 10px
  bottom 40px
  cursor pointer
.accept-btn
  width : 100%
  display flex
  justify-content flex-end
  p
    padding  4px 16px 5px 16px
    width 115px
    background: #3C5E8F;
    border-radius: 8px;
    color white
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 120%;

.symbol
  fill: red!important

.cluster-radius
  width: fit-content;
  height: 33px;
  border: 1px solid #d1dbe4;
  border-radius: 5px;
  .radiusBtn
    &:hover
      svg
        rect
          fill: white

  .cluster-radius-count
    margin 0 !important
.cluster-radius span
  position: relative;
  padding: 5px 15px;
  color: #5477a9;
  cursor: pointer;
  transition all 0.2s ease-in-out
  height 100%
  border-radius: 4px 0px 0px 4px
  &:hover
    background-color #446CA7
    color white
    &:first-child
      border-radius: 4px 0px 0px 4px
    &:last-child
      border-radius: 0px 4px 4px 0px

.cluster-radius span:before
  content: '';
  position: absolute;
  top: -1px;
  width: 1px;
  height: 33px;
  background: #d1dbe4;

.cluster-radius span:first-child:before
  right: 0;

.cluster-radius span:last-child:before
  left: 0;

.cluster-radius p
  padding: 0 18px;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #333333;

.radiusSettings
  margin-left auto
.pointer
  cursor pointer

.title
  display: flex
  align-items center
  justify-content space-between
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;

.fasttrack
    width: 100%

  .items-container
    display flex
    flex-direction column

    .poppover-item
      margin-bottom 6px
      padding 3px 10px
      font-family: 'Source Sans Pro'
      font-style: normal
      font-weight: 600
      font-size: 14px
      line-height: 120%
      color #292929
      cursor pointer
      &:hover
        background #EDF4FF
        transition: background 0.3s 0s ease

.row
  &__unit
    display grid
    grid-template-columns 1fr 1fr
    grid-template-rows 48px 1fr
    gap 0px 0px
    grid-template-areas "area1 area2" \
      "area3 area3"
    justify-content space-between
    align-items center
    position relative
    &::after
      content ''
      height 1px
      width calc(100% - 48px)
      background #ccc
      position absolute
      bottom 0
      margin-left auto
      margin-right auto
      left 0
      right 0
      z-index 31
    .row-icons
      display flex
      .object-arrow
        margin-left  20px
      .status
        margin-left 20px
    .area1
      grid-area area1
      position sticky
      top 0
      background #fff
      height 48px
      z-index 31
      display flex
      align-items center
      margin-left 20px
      width 100%
    .area2
      grid-area area2
      position sticky
      top 0
      background #fff
      height 48px
      z-index 31
      padding-right 20px
      justify-content flex-end
      justify-self flex-end
      align-items center
      width 100%
    .area3
      grid-area area3
      align-self flex-start

      li
        display grid
        grid-template-columns 20px 1fr 170px
        align-items center
        height 48px
        margin-left 48px
        .row-icons
          justify-content end
          align-items center
          margin-right 13px
    &--active
      overflow-y scroll
      height auto
      max-height 67%
      &::after
        content none
.objects-eye {
  margin-left : 10px !important
}

.objects-status{
  margin-right : 1px !important
}
#scroller
  height 69vh
  overflow-y auto
  display flex
  padding-right 0

  .row-name
    cursor default
    display flex
    align-items center

    .extra-menu
      width 16px
      height 16px
      opacity 0

    .moreActions
      cursor pointer
    &:hover
      .extra-menu, .annotation-icon
        opacity 1

  .row
    padding-left 10px
    height 48px
    display flex
    align-items center
    justify-content space-between

    &__group
      &:after
        content ''
        position absolute
        width calc(100% - 24px)
        bottom 0
        height 1px
        background-color #edeff3

    &:hover
      background red
      background #EDF4FF
      transition: background 0.3s 0s ease
  .group-objects-list
    position relative
    .linear
      width 4px
      background #EDEFF3
      position absolute
      top 0px
      bottom 20px
      left 20px

  .row-icons
    display flex
    align-items center
    justify-content flex-end
    .object-arrow
      margin-left 25px
      cursor pointer
    .eye
      margin-left 22px

      cursor pointer
    .target
      cursor pointer
    .status
      position relative
      right -5px
      min-width: 65px
      padding 0 5px
      margin-left 0

    &:hover
      .extra-menu, .annotation-icon
        opacity 1
.notActive:hover
  background #D2EAFF
  padding: 10px
  border-radius 50%
  display block

.icon-hovering
  padding 4px
  border:2px solid transparent
  transition: all 0.5s ease
.poppover-container
  font-family: 'Source Sans Pro'
  font-style: normal
  font-weight: 600
  color #292929
  font-size: 14px
  line-height: 120%
  display flex
  flex-direction column
  z-index 32

  .poppover-item
    padding 0 10px
    cursor pointer
    display flex
    align-items center
    height 30px
    &:hover
      background #EDF4FF
      transition: background 0.3s 0s ease

.monitoring__footer
  height 35px
  display flex
  align-items center
  justify-content space-between
  color: #828D9B
  font-size 14px
  padding 0 20px
  .size-container
    display flex
    .size-btns-container
      display flex
      .small, .medium, .large
        background #EDEFF3
        border 1px solid #A6AEB8
      .small
        width: 12px
        height 16px
        margin-left 6px
        border-radius 2px
      .medium
        width: 20px
        height 16px
        margin-left 6px
        border-radius 2px
      .large
        width: 24px
        height 16px
        margin-left 6px
        border-radius 2px
      .active-size
        border 1px solid #314E75
        border-radius 2 px


.container
  padding 17px 20px

.monitoring__table
  height 73vh
  overflow hidden
  width: 100%
  border-bottom 1px solid #BEC7D3
  // &--isActiveGroup
  //   overflow-y scroll

  &-header
    position sticky
    top 0
    z-index 32
    background white
    display flex
    justify-content space-between
    align-items center
    padding 0 20px 10px 40px
    border-bottom 1px solid #BEC7D3
    .name-container
      padding-left 10px
      display flex
      align-items center
      .name
        margin-right 11px
    .sorting-container
      display: flex
      align-items center
      // margin-right 10px
      .sorting-icon
        // margin-left 32px
        margin-right: 2px
      // .sorting-icon:nth-of-type(1)
      //   margin-right 9px
      //   margin-left 19px
      // .sorting-icon:nth-of-type(2)
      //   margin-left: 21px;
      //   margin-right: 0px;
      .sorting-icon:nth-of-type(1)
        margin-right 0
        margin-left 26px
      .sorting-icon:nth-of-type(2)
        margin-right 0
        margin-left 26px
      .sorting-icon:last-child
        margin-right 0
        margin-left 26px



.monitoring
  font-family 'Source Sans Pro'
  font-size 16px
  color #000000
  font-style normal
  font-weight 600
  line-height 120%
  position absolute
  margin-right 20
  top 20px
  left 78px
  z-index 30
  background-color white
  width 500px
  resize horizontal
  overflow hidden
  min-width 500px
  max-width 800px
  border-radius 12px
  .monitoring__header
    display flex
    max-width 99vw
    .monitoring__title
      font-size 24px
      font-weight 700
    .monitoring__toReport
      margin-left 24px
      color  #446CA7
      .monitoring__toReportTitle
        margin-left 8px
    .monitoring__close
      color #446CA7
      margin-left auto
      display flex
      align-items center

  .monitoring__search
    width: 100%
    position relative
    margin-top 20px
    .monitoring__input
      border  1px solid #CDD4DD
      border-radius: 4px
      height: 36px
      width  100%
      background: #F6F7FA
      outline: none
      font-size: 14px
      padding: 10px
    .monitoring__input::placeholder
      color #828D9B
      font-weight: 600
    .monitoring__search__icon
      color red
      position absolute
      top 30%
      right 10px

  .monitoring__options
    display flex
    justify-content: space-between
    margin-top 13px

    .monitoring__btns
      display flex
      &-btn
        position relative
        // margin-left 10px
        padding  7px
        border 1px solid #CDD4DD
        background: #F6F7FA
        border-radius: 4px
      .btn-eye
        margin-right 6px
      .btn-arrow
        margin-right 6px

    .monitoring__filter
      display: flex
      align-items: center
      justify-content: center
      &-icon
        margin-right 10px
      &-title
        color #446CA7
      &-number
        margin-left 10px
        color : #ffffff
        font-size 14px
        background-color: #446CA7
        padding 0 5px
        border-radius 50%
.empty
  background-color #BEC7D3
  margin-left 10px
  color : #ffffff
  font-size 14px
  padding 0 5px
  border-radius 50%

  &__unit-name
    width 265px
    white-space nowrap
    overflow hidden
    text-overflow ellipsis
    margin-left 12px

.sort
  opacity 0.48
  transition all 0.3s
  &--asc, &--desc
    opacity 1
    transition all 0.3s
  &--desc
    transform rotate(180deg)
  &:hover
    cursor pointer
.slide-fade-enter-active
  transition: all 0.4s ease;

.slide-fade-leave-active
  transition: all 0.4s cubic-bezier(1, 0.5, 0.8, 1);

.slide-fade-enter,
.slide-fade-leave-to

/* .slide-fade-leave-active below version 2.1.8 */
  transform: translateY(10px);
  opacity: 0;
.text-error
  color #F56C6C
.units-group
  display flex
  justify-content center
  align-items center
  width 20px
  height 20px
  border-radius 50%
  background-color #EDEFF3
  cursor pointer
  margin-left 20px
  transition all 0.3s
  &--active
    transform rotate(-180deg)
// .monitoring__unit-name{
//   min-width: 90%
// }
.group-unit-name
  display-inline block
  margin-left 5px
.monitoring__unit-name
  align-items center
  margin-left 14px
  text-overflow ellipsis
  white-space nowrap
  overflow hidden

  &--without-group
    margin-left 34px

.monitoring {
  &__unit-name-group {
    display inline-block
  }
}

.count {
  display flex
  justify-content center
  width: 40px;
}
.count-group
  padding 2px 7px
  height 23px
  margin-left 8px
  font-weight 600
  font-size 14px
  // line-height 120%
  color #FFFFFF
  background #BEC7D3
  border-radius: 35%/50%;
  margin-top 1px
.group-container
  display grid
  grid-template-columns calc(100% - 160px) 160px
  grid-template-rows 48px 1fr
  gap 0px 0px
  grid-template-areas:
    "area1 area2" \
    "area3 area3"

  &::after
    content ''
    position absolute
    width 100%
    bottom 0
    height 1px
    background-color #edeff3
.group-items
  display flex
  height 48px
  padding-right 25px
  padding-left 30px
  align-items center
  justify-content space-between
  .group-unit-name
    width 100%
  .row-icons
    position relative
    right -20px

.area1
  grid-area area1
  padding-left 20px
.area2
  grid-area area2
  margin-right 25px
.area3
  grid-area area3
.activeGroup
  border 1px solid #828D9B !important
  border-radius 4px
.group-items
  width: 100%;

.group-menu-icon
  opacity 1 !important
.annotation-icon
  cursor pointer
  opacity 0
  &--isCamera
    margin-right 25px

.active-annotation-icon
  cursor pointer
  opacity 1

.isOpened
  background #F6F7FA
</style>
